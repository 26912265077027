* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "futura-pt", sans-serif;
  scroll-behavior: smooth;
}

.page-not-found-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.page-not-found-container > h1 {
  font-size: 3rem;
  text-align: center;
}

.page-not-found-container > h2 {
  font-size: 2rem;
  cursor: pointer;
  margin-top: 80px;
  color: rgb(70, 70, 70);
  text-decoration: underline;
}
